<template>
    <div>
      <div class="collect-sponsor">
        <div class="title hidden-sm-and-down">
          Following Organizers
        </div>
        <div class="sponsor-list">
          <div class="sponsor-item" v-for="(item,index) in sponsorList.slice((pageCur-1)*pageCount,pageCur*pageCount)" :key="index">
            <el-row type="flex">
              <el-col :xs="5" :sm="5" :md="3" >
                <img class="logo" width="100%" :src="item.images" alt="" @click="godetail(item.SPONSOR_ID)">
              </el-col>
              <el-col :xs="19" :sm="19" :md="15">
                <div class="sponsor-name" @click="godetail(item.SPONSOR_ID)">
                  <span>
                    {{ item.ENNAME == '' ? item.CNNAME : item.ENNAME }}
                  </span>
                  <img v-show="item.ISRZ != 'no'" src="../../assets/images/Q/Q1/vip-icon.png" alt="">
                </div>
                <div class="txt">
                  {{item.SHORTCONTENT}}
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="6">
                <div class="collect-btn" @click="deleteCollect(item.SPONSOR_ID)">
                  <i class="el-icon-remove"></i>
                  <span>Cancel following organizers</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <el-pagination class="hidden-sm-and-down" background layout="prev, pager, next" prev-text="Prev" next-text="Next" hide-on-single-page :total="sponsorList.length" :page-size="pageCount" :current-page="pageCur" @prev-click="prev()" @next-click="next()" @current-change="changepage"></el-pagination>
      </div>
    </div>
</template>
<script>
import { delCollect, collectSponsor } from '../../api'
import qs from 'qs'
export default {
  name: 'cityG5',
  props: {
    msg: String
  },
  data () {
    return {
      sponsorList: [],
      // 每页显示条目个数
      pageCount: 10,
      // 当前页数
      pageCur: 1
    }
  },
  inject: ['reload'],
  methods: {
    prev () {
      this.pageCur--
    },
    next () {
      this.pageCur++
    },
    deleteCollect (id) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        SPONSORID: id,
        USERID: userInfo.userId
      }
      this.$confirm('Are you sure to cancel colleting the organizer? ', 'Tips', {
        confirmButtonText: 'Confirm',
        cancelButtonText: ' Cancel',
        type: 'warning'
      }).then(() => {
        delCollect(qs.stringify(data)).then((res) => {
          if (res.data.result === 'ok') {
            this.$message({
              type: 'success',
              message: 'Success！',
              center: true
            })
            this.reload()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Cancel！',
          center: true
        })
      })
    },
    changepage(e){
      this.pagecur = e
    },
    godetail(id){
      this.$router.push({
        name:'cityQ4e',
        query:{
          id :id
        }
      })
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        if (sc.isSZSMT()) {
          console.log('在i深圳平台')
          this.$parent.linkIshenzhen()
        } else {
          console.log('不在i深圳平台内')
          this.$router.push('/LoginEN')
        }
      })
    } else {
      collectSponsor(userInfo.userId).then(res => {
        this.sponsorList = res.data
      })
    }
  }
}
</script>
<style lang="stylus" scoped src="../../assets/css/CityGe.styl"></style>
<style lang="less">
.G-container.en{
  .collect-sponsor{
    .el-pagination{
      text-align: center
    }
    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
      margin: 0;
      border-radius: 0;
      border :0.0185rem solid #e2e2e2;
      background :#fff;
      padding: 0 .24rem;
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
      background: #a5cd53;
      border: 0.0185rem solid #a5cd53;
    }
  }
  .sponsor-list{
    padding-bottom: 1rem;
    .el-row--flex{
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
@media screen and (max-width:990px){
  .G-container.en {
    .content-box{
      .content{
        .collect-sponsor{
          .sponsor-list{
            border-top: 1px solid #c9caca;
            margin-bottom: 1rem;
            .sponsor-item{
              padding-bottom: 0;
              .sponsor-name{
                font-size: 0.4rem;
                margin-bottom: 0.18rem;
              }
              .txt{
                font-size: 0.3rem;
                padding-bottom: 0.3rem;
              }
              .collect-btn{
                border-top: 1px solid #c9caca;
                /*border-left: 1px solid #c9caca;*/
                /*border-right: 1px solid #c9caca;*/
                padding: 0.2rem 0.2rem;
                font-size: 0.3rem;
              }
            }
          }
        }
      }
    }
  }

}

</style>
